import "core-js/modules/es.symbol.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.promise.finally.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { mapGetters } from 'vuex';
import LayoutAdmin from '@/components/layouts/LayoutAdmin.vue';
export default defineComponent({
  components: {
    LayoutAdmin: LayoutAdmin
  },
  data: function data() {
    return {
      loadingTable: true,
      mainNavDrawer: false
    };
  },
  head: function head() {
    return {
      title: 'Business - Seals'
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters('business', ['seals'])), {}, {
    sealsTableHeader: function sealsTableHeader() {
      var headers = [{
        text: this.$t('business.seals.table.headers.account_name'),
        value: 'accountName',
        sortable: false
      }, {
        text: this.$t('business.seals.table.headers.display_name'),
        value: 'displayName',
        sortable: false
      }, {
        text: this.$t('business.seals.table.headers.amount_api_keys'),
        value: 'amountApiKeys',
        sortable: false
      }, {
        text: this.$t('business.seals.table.headers.amount_members'),
        value: 'amountMembers',
        sortable: false
      }, {
        text: '',
        value: 'options',
        sortable: false,
        class: 'shrink'
      }];
      return headers;
    },
    filteredSeals: function filteredSeals() {
      var _this$seals;
      return (_this$seals = this.seals) === null || _this$seals === void 0 ? void 0 : _this$seals.filter(function (seal) {
        return seal.accountName !== 'ais_demo_seal';
      });
    }
  }),
  created: function created() {
    var _this = this;
    this.loadingTable = true;
    void this.$store.dispatch('business/getSealsByBusiness').finally(function () {
      _this.loadingTable = false;
    });
  },
  methods: {
    goToSealEditView: function goToSealEditView(sealName) {
      void this.$router.push({
        name: 'business-seals-accountname',
        params: {
          accountname: sealName
        }
      });
    }
  }
});